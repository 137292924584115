body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* -- Global Styles -- */
* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  line-height: 1.35;
  background-color: #ffffff;
}

.hidden {
  display: none;
}

h1,
h1,
h2,
h3,
h4 {
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1.15;
  position: relative;
}

h1 {
  margin: 0;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.3em;
}

h3 {
  font-size: 1.7em;
}

h4 {
  font-size: 1.5em;
}

h5 {
  font-size: 1.5em;
  font-weight: bold;
  color: #35817F;
}

@media screen and (min-width: 1024px) {
  h2 {
    font-size: 1.5em;
  }
}

p {
  font-size: 1em;
}

a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
  transition: color .2s ease-in-out;
}

a:visited {
  color: inherit;
}

a:hover {
  color: #FF9446;
}

img {
  width: 70%;
}

video {
  width: 100%;
  border-radius: 30px;
}

.btn {
  padding: 15px 45px;
  background: linear-gradient(to right, #FF9446, #FFCC7E, #FF9446);
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  background-size: 300% 100%;
  transition: all .4s ease-in-out;
  font-family: Nunito;
}

.btn span {
  display: inline-block;
  transition: all .2s ease-in-out;
}

.btn:hover {
  background-position: 100% 0;
}

.btn:hover span {
  transform: translateX(7px);
}

.sparkles-left {
  width: 30px;
  transform: scaleX(-1);
}

.sparkles-right {
  width: 30px;
}

@media screen and (min-width: 1024px) {
  .sparkles-left {
    width: 50px;
    transform: scaleX(-1);
  }

  .sparkles-right {
    width: 50px;
  }
}

header {
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1% 5%;
  background-color: rgba(255, 255, 255, .01);
  backdrop-filter: blur(10px);
  z-index: 2;
}

header #logo {
  max-width: 140px;
}

nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #ffffff;
  width: 100%;
  padding: 80px 5% 2% 5%;
  z-index: -2;
  text-align: right;
  transition: transform .5s;
  box-sizing: border-box;
  transform: translateX(110%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

nav a {
  margin-bottom: 15px;
}

.checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  right: 5%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 25px;
  right: 5%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  background: #0e2431;
}

.hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

header input[type="checkbox"]:checked~.hamburger-lines .line1 {
  transform: rotate(45deg);
}

header input[type="checkbox"]:checked~.hamburger-lines .line2 {
  transform: scaleY(0);
}

header input[type="checkbox"]:checked~.hamburger-lines .line3 {
  transform: rotate(-45deg);
}


@media screen and (min-width: 1025px) {
  nav {
    flex-direction: row;
    transform: translateX(0%);
    position: relative;
    padding: 0;
    text-align: left;
    justify-content: flex-end;
    width: auto;
    background-color: transparent;
  }

  nav a {
    margin-bottom: 0;
    margin-left: 35px;
  }

  #mobile-btn {
    display: none;
  }
}

.mobile-open {
  transform: translateX(0%);
}

section {
  padding: 5%;
}

@media screen and (min-width: 2000px) {

  header,
  section,
  footer {
    padding: 5% 10%;
  }
}

section p {
  margin-bottom: 15px;
}

#hero {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 100px;
  background: linear-gradient(#f1f1f1, #FFCC7E);
  background-attachment: fixed;
}

#hero #clouds {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./img/assets/clouds.png);
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: 100%;
  z-index: 0;
}

#hero article {
  width: 100%;
  text-align: center;
  z-index: 1;
}

#hero #video-wrapper {
  width: 100%;
  z-index: 1;
  margin-top: 25px;
}

#hero #video-wrapper video {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

#hero aside {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  z-index: 1;
}

#hero aside p {
  width: 100%;
  text-align: center;
  font-size: .8em;
  margin: 25px 0;
}

@media screen and (min-width: 1024px) {
  h1 {
    font-size: 3em;
  }

  #hero {
    min-height: auto;
    padding-top: 20vh;
  }

  #hero article {
    width: 50%;
    text-align: left;
    padding-right: 10%;
  }

  #hero #video-wrapper {
    width: 50%;
    margin: 0;
  }

  #hero aside {}
}

@media screen and (min-width: 1025px) {
  #hero {
    min-height: 100vh;
  }
}

.center-form {
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

.left-form {
  background-image: url(./img/assets/orange-blur.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column-reverse;
}

.right-form {
  background-image: url(./img/assets/teal-blur.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 1024px) {
  .center-form h3 {
    font-size: 2.75em;
  }

  .left-form,
  .right-form {
    align-items: center;
  }

  .left-form h4,
  .right-form h4 {
    font-size: 2.5em;
  }

  .left-form p,
  .right-form p,
  .left-form a,
  .right-form a {
    font-size: 1.2em;
  }

  .left-form article,
  .right-form article,
  .left-form figure,
  .right-form figure {
    width: 50%;
  }

  .left-form article,
  .right-form figure {
    padding-right: 5%;
  }

  .left-form figure,
  .right-form article {
    padding-left: 5%;
  }

  .left-form {
    flex-direction: row;
  }

  .right-form {
    flex-direction: row-reverse;
  }
}

#rev-model div span {
  font-size: 5em;
  font-weight: bold;
  color: #35817F;
}

@media screen and (min-width: 1024px) {
  #rev-model {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #rev-model div {
    width: 25%;
  }
}

footer {
  padding: 5%;
  padding-bottom: 2%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer h4 {
  font-size: 1.1em;
}

footer img {
  width: 150px;
}

#companynamefooter {
  font-size: 3em;
  font-weight: bold;
  color: #0e6d6a;
}